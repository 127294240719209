import React from 'react';
import PropTypes from 'prop-types';

import ListItem from '@mui/material/ListItem/ListItem';

/**
 * List item component
 * @module
 * @param {func} [onClick] - List item click handler function
 */
class ListItemComponent extends React.Component {
  render() {
    return (
      <ListItem
        onClick={this.props.onClick}
        style={this.props.style}
      >
        {this.props.children}
      </ListItem>
    )
  }
}

ListItemComponent.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
}

export default ListItemComponent;