import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  backgroundColor: '#7a7a7a',
  opacity: 0.8,
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1101,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const circularProgress = {
  color: '#ffffff',
}
/**
 * Circular progress component
 * @module
 * @param {bool} loading - Whether to show circular progress
 */
class CircularProgressComponent extends React.Component {
  render() {
    if (this.props.loading)
      return (
        <div style={style} className={this.props.className}>
          <CircularProgress 
            size={60} 
            variant="indeterminate" 
            style={circularProgress}
          />
        </div>
      );
    else
      return null;
  }
}

CircularProgressComponent.propTypes = {  
  loading: PropTypes.bool.isRequired,  
}
export default CircularProgressComponent;