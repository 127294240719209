import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../coraWebMComponents/inputs/TextField';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import AppBar from '../appBar/AppBarDrawer';
import Error from '../../coraWebMComponents/feedBack/Error';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import AttachmentInput from '../../coraWebMComponents/inputs/AttachmentInput';

import routes from '../../routes';
import * as validator from '../../coraWebMComponents/utils/validator';
import * as parkingActions from '../../actions/ParkingActions';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import withMeta from '../../coraWebMComponents/utils/withMeta';

class EvoSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ecv: '',
      vin: '',
      reason: '',
      errorTextReason: '',
      errorText: '',
    };
  }

  handleChangeEcv = (name, value) => {
    this.setState({
      ecv: value.toUpperCase()
    });
  };

  handleChangeVin = (name, value) => {
    this.setState({
      vin: value.toUpperCase()
    });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleSearch = () => {
    const reason = this.state.reason;
    const ecv = this.state.ecv.trim();
    const ecvError = validator.isValidEcv(ecv);
    const vin = this.state.vin;

    const errorText = (ecvError.length === 0 || vin) ? '' : 'Je nutné zadať EČV alebo VIN. ' + ecvError;
    const errorTextReason = reason ? '' : 'Je nutné zadať dôvod.';
    this.setState({ errorText, errorTextReason });

    if (reason && !errorText) {
      this.props.navigate(routes.EVO, { state: this.state });
    }
  };

  handleChangeImage = async (image) => {
    await this.props.dispatch(parkingActions.getParkingAlpr(image.data, this.props.meta.checkAppOffline));
    if (this.props.alpr.Status === 200 && this.props.alpr.Data.length > 0) {
      this.setState({
        ecv: this.props.alpr.Data[0].Characters
      });
    }
  };

  render() {
    return (
      <div>
        {/* <AppBar title='Lustrácia vozidiel v evidencii vozidiel Policajného zboru MV SR (EVO)' /> */}
        <AppBar title='EVO' />
        <div className='container'>
          <Paper className='content' elevation={1} >
            <React.Fragment>
              <h3>Kontrola záznamov o vozidle</h3>
              <div className="row">
                <div style={{ display: 'flex', alignItems: 'flex-end', width: '49%' }}>
                  <TextField
                    style={{ width: '100%' }}
                    label="EČV vozidla"
                    onChange={this.handleChangeEcv}
                    value={this.state.ecv}
                    name='ecv'
                    variant="standard"
                  />
                  <CircularProgress loading={this.props.alpr.IsLoading} />
                  <AttachmentInput
                    disabled={false}
                    onChange={this.handleChangeImage}
                    iconType={1}
                  />
                </div>
                <TextField
                  style={{ width: '49%', marginTop: 4 }}
                  label="VIN"
                  onChange={this.handleChangeVin}
                  value={this.state.vin}
                  name='vin'
                  variant="standard"
                />
              </div>
              <Error text={this.state.errorText} />
              <TextField
                label="Dôvod"
                errorText={this.state.errorTextReason}
                error={this.state.errorTextReason ? true : false}
                onChange={this.handleChange}
                value={this.state.reason}
                fullwidth={true}
                name='reason'
                variant="standard"
              />
              <RaisedButton
                fullwidth={true}
                onClick={this.handleSearch}
              >
                {'Vyhľadať'}
              </RaisedButton>
            </React.Fragment>
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alpr: state.alpr
  };
};

export default withRouter(withMeta(connect(mapStateToProps)(EvoSearch)));