import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  selectMenu: {
    background: 'transparent!important',
    textAlign: "left!important",
  },
  helperText: {
    '&.MuiFormHelperText-root.Mui-error': {
      margin: "3px 0px 0px!important",
      // color: 'rgb(211, 47, 47)!important',
    },
  },
  inputLabel: {
    // marginLeft: '-0.85rem', // Posunie label mierne doľava
    // marginTop: "8px", // Posunie label mierne dole
    // color: "gray!important", // TODO: v MUI5 premysliet ako to obist, lebo label je sivy aj pri error hlaske
    // '&.Mui-focused': {
    //   color: "gray!important",
    // },
    // '&.Mui-error': {
    //   color: 'rgb(211, 47, 47)!important',
    // },
    // '&:not(.Mui-error)': {
    //   color: 'black!important',
    // },
    fontStyle: "normal!important",
  },
  menuItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "4px 16px",
  }
}));

const SimpleSelectComponent = ({
  schema, data, required, floatingLabelText, name, multiple, fullwidth, disabled, error, className, value, icon, onChange, defaultLabelText, wrap, errorText
}) => {
  const classes = useStyles();

  const renderValue = (value) => {
    if (multiple) {
      const selectedData = data.filter(x => value.includes(x[schema.value]));
      if (selectedData) {
        if (wrap) {
          return (
            <Box
              sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, whiteSpace: "pre-wrap" }}
            >
              {selectedData.map((x, index) => (
                <>
                  {index > 0 && ", "}
                  <div key={x[schema.value]}>{x[schema.label]}</div>
                </>
              ))}
            </Box>
          );
        }
        return selectedData.map(x => x[schema.label]).join(", ");
      }
    } else {
      const item = data.find(x => x[schema.value] === value);
      if (item) {
        return item[schema.label];
      }
    }
    return '';
  };

  return (
    <FormControl
      fullWidth={fullwidth}
      disabled={disabled}
      error={error}
      className={className}
    >
      <InputLabel htmlFor={name} variant="standard" classes={{ root: classes.inputLabel, standard: classes.inputLabel }}>
        {floatingLabelText}
      </InputLabel>

      <Select
        multiple={multiple}
        value={value || (icon ? " " : "")}
        onChange={event => onChange(name, event.target.value)}
        inputProps={{ id: name }}
        renderValue={renderValue}
        classes={{ root: classes.selectMenu, standard: classes.selectMenu }}
        variant="standard"
        MenuProps={{
          getContentAnchorEl: () => null,
        }}
        // sx={{  // TODO: v MUI 5 premysliet ako to obist, lebo podciarknutie je sive natvrdo
        //   ':before': { borderBottomColor: 'gray' },
        //   ':after': { borderBottomColor: 'gray' },
        // }}
        startAdornment={
          icon && (
            <InputAdornment position="start" style={{ marginLeft: '-2px' }}>
              {icon}
            </InputAdornment>
          )
        }
      >

        {!required && !multiple &&
          <MenuItem value='' classes={{ root: classes.menuItem }}>{defaultLabelText}</MenuItem>
        }

        {data?.map((x) =>
          <MenuItem key={x[schema.value]} value={x[schema.value]} classes={{ root: classes.menuItem }}>
            <Box>
              {multiple && <Checkbox checked={value.indexOf(x[schema.value]) > -1} />}
              {x[schema.label]}
            </Box>
          </MenuItem>
        )}
      </Select>
      {error && <FormHelperText classes={{ root: classes.helperText }}>{errorText}</FormHelperText>}
    </FormControl>
  );
};

SimpleSelectComponent.propTypes = {
  disabled: PropTypes.bool,
  floatingLabelText: PropTypes.string,
  defaultLabelText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  onChange: PropTypes.func,
  fullwidth: PropTypes.bool,
  data: PropTypes.array.isRequired,
  schema: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.element,
  multiple: PropTypes.bool,
  wrap: PropTypes.bool
};

export default SimpleSelectComponent;