import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../coraWebMComponents/inputs/TextField';
import DatePickerV5 from '../../coraWebMComponents/inputs/DatePickerV5';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import AppBar from '../appBar/AppBarDrawer';
import Error from '../../coraWebMComponents/feedBack/Error';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import routes from '../../routes';
import * as validator from '../../coraWebMComponents/utils/validator';
import * as trespassActions from '../../actions/TrespassActions';
import * as personActions from '../../actions/PersonAction';
import Autocomplete from '../../coraWebMComponents/inputs/Autocomplete';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import withMeta from '../../coraWebMComponents/utils/withMeta';

class DogSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoad: true,
      sign: '',
      cip: '',
      rc: '',
      name: '',
      surname: '',
      birthDate: null,
      errorText: '',
      errorTextRc: '',
      L_ACCESS_PERSON: false,
      person: { I_O: 0, OSOBA: '' }
    };
  }

  componentDidMount = async () => {
    await this.props.dispatch(trespassActions.getAccessPerson(this.props.meta.checkAppOffline));
    this.setState({ isLoad: false, L_ACCESS_PERSON: parseInt(this.props.phAccessPerson.HODN), });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangePersonList = (searchText) => {
    searchText = searchText.trim();
    const errorTextRc = validator.isValidRc(searchText) ? '' : 'RČ môže obsahovať iba číslice (max. 10).';
    this.setState({ errorTextRc });
    this.props.dispatch(personActions.getPersonList(searchText, window.config.autocompleteCountCharStart_person || 6, this.props.meta.checkAppOffline));
  };

  handleSelectPerson = async (name, value) => {

    if (value) {
      if (value.I_O > 0) {
        await this.props.dispatch(personActions.getPerson(value.I_O, this.props.meta.checkAppOffline));
        this.setState({
          person: { I_O: value.I_O, OSOBA: this.props.person.ICO_RC },
          rc: this.props.person.ICO_RC.trim(),
          name: this.props.person.MENO ? this.props.person.MENO : '',
          surname: this.props.person.PF,
          birthDate: this.props.person.D_NAR ? new Date(this.props.person.D_NAR) : null
        });
      }
      else
        this.setState({ person: value, rc: value.OSOBA, name: '', surname: '', birthDate: null });
    }
    else
      this.setState({ person: { I_O: 0, OSOBA: '' }, rc: '', name: '', surname: '', birthDate: null });

  };

  handleSearch = () => {
    const sign = this.state.sign;
    const cip = this.state.cip;
    const rc = this.state.rc.trim();
    const surname = this.state.surname;
    const birthDate = this.state.birthDate;

    if (sign || cip || validator.isValidRc(rc) || surname || (birthDate instanceof Date)) {
      this.setState({ errorText: '' });
      this.props.navigate(routes.DOG, { state: this.state });
    }
    else {
      this.setState({ errorText: 'Je nutné zadať číslo známky, číslo čipu, rodné číslo bez lomítka, priezvisko alebo dátum narodenia (DD.MM.YYYY).' });
    }
  };

  render() {
    return (
      <div>
        <AppBar title='Pes' />
        <div className='container'>
          <Paper className='content' elevation={1} >
            <React.Fragment>
              <h3>Kontrola čísla známky psa</h3>
              <TextField
                label="Číslo známky"
                onChange={this.handleChange}
                value={this.state.sign}
                fullwidth={true}
                name='sign'
                variant="standard"
              />
              <TextField
                label="Číslo čipu"
                onChange={this.handleChange}
                value={this.state.cip}
                fullwidth={true}
                name='cip'
                variant="standard"
              />
              {this.state.L_ACCESS_PERSON === 0 &&
                <TextField
                  fullwidth={true}
                  label="RČ/IČO"
                  onChange={this.handleChange}
                  value={this.state.rc}
                  name='rc'
                  variant="standard"
                />
              }
              {this.state.L_ACCESS_PERSON === 1 &&
                <Autocomplete
                  data={this.props.personList}
                  total={this.props.totalPerson}
                  isLoading={this.props.isLoadingPersonList}
                  schema={{ label: 'OSOBA', value: 'I_O' }}
                  onChange={this.handleChangePersonList}
                  onSelect={this.handleSelectPerson}
                  selectedItem={this.state.person}
                  label="RČ/IČO"
                  errorText={this.state.errorTextRc}
                  name='person'
                  countCharStart={window.config.autocompleteCountCharStart_person || 6}
                />}
              <div className="row">
                <TextField
                  style={{ width: '43%' }}
                  label="Meno"
                  onChange={this.handleChange}
                  value={this.state.name}
                  name='name'
                  variant="standard"
                />
                <TextField
                  style={{ width: '55%' }}
                  label="Priezvisko/Názov"
                  onChange={this.handleChange}
                  value={this.state.surname}
                  name='surname'
                  variant="standard"
                />
              </div>
              <DatePickerV5
                onlyMobileVersion={true}
                placement={"auto"}
                name='birthDate'
                label="Dát. nar. os."
                format="D.M.YYYY"
                fullWidth={true}
                value={this.state.birthDate}
                // defaultValue={}
                onChange={this.handleChange}
                clearable={true}
                keyboards={true}
                disableFuture={true}
                disablePast={false}
                // className={classNames(classes.mt2)}
                closeOnSelect={false}
                disabled={false}
                disableOpenPicker={false}
                displayWeekNumber={false}
                formatDensity={"dense"}
                localeText={{}}
                openTo={"year"}
                reduceAnimations={true}
                showDaysOutsideCurrentMonth={true}
                yearsOrder="desc"
                actions={['clear', 'cancel', 'accept']}
              />
              <RaisedButton
                fullwidth={true}
                onClick={this.handleSearch}
              >
                {'Vyhľadať'}
              </RaisedButton>
              <Error text={this.state.errorText} />
              <CircularProgress loading={this.state.isLoad || this.props.isLoadingPerson} />
            </React.Fragment>
          </Paper>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    personList: state.personList.data,
    totalPerson: state.personList.total,
    isLoadingPersonList: state.personList.isLoading,
    person: state.person.data.Data,
    isLoadingPerson: state.person.isLoading,
    phAccessPerson: state.phAccessPerson.data,
  };
};

export default withRouter(withMeta(connect(mapStateToProps)(DogSearch)));