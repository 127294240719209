import React from 'react';
import ListItem from '../../coraWebMComponents/dataDisplay/ListItem';
import List from '../../coraWebMComponents/dataDisplay/List';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import AddIcon from '../../coraWebMComponents/dataDisplay/icons/AddIcon';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import AppBar from '../appBar/AppBar';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import LinearProgress from '../../coraWebMComponents/feedBack/progress/LinearProgress';
import Detail from '../../coraWebMComponents/dataDisplay/Detail';
import { connect } from 'react-redux';
import routes from '../../routes';
import * as convert from '../../coraWebMComponents/utils/convert';
import * as trespassActions from '../../actions/TrespassActions';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import withMeta from '../../coraWebMComponents/utils/withMeta';

class TrespassPerson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      detail: '',
      openDialog: true,
      disableTrespassAdd: true,
    };
  }

  handleCloseAlert = () => {
    this.setState({ openDialog: false });
    this.props.navigate(routes.PERSON_SEARCH);
  };

  newTrespass = (person) => {
    this.handleCloseAlert();
    this.props.navigate(routes.TRESPASS_ADD, { state: this.props.location.state });
  }

  personSearchStateFromPerson = (person) => {
    return {
      rc: person.ICO_RC ? person.ICO_RC : '',
      name: person.MENO ? person.MENO : '',
      surname: person.PF ? person.PF : '',
      IDNumber: person.C_OP ? person.C_OP : ''
    };
  }

  handleOpenPersonSearch = (person) => {
    this.props.navigate(routes.TRESPASS_ADD, { state: this.personSearchStateFromPerson(person) });
  }

  handleOpen = (id) => {
    const trespass = this.props.trespasses.find(trespass => trespass.I_PR === id);
    const detail = (
      <div>
        <div><b>Dňa:</b> {convert.convertDate(trespass.D_PR)}</div>
        <div><b>Meno a priezvisko priestupcu:</b> {trespass.OSOBA}</div>
        <div><b>Dát. nar.:</b> {convert.convertDate(trespass.D_NAR)}</div>
        <div><b>Adresa TP:</b> {trespass.ADRESA}</div>
        <div><b>Porušenie predpisu:</b> {trespass.N_PRED}</div>
        <div><b>Rola v priestupku:</b> {trespass.ROLA}</div>
        <div><b>Opis priestupku:</b> {trespass.OPIS_PR}</div>
        <div><b>Ulica priestupku:</b> {trespass.N_U}</div>
        <div><b>Motorové vozidlo:</b> {trespass.ECV}</div>
        <div><b>Spôsob riešenia:</b> {trespass.RIESENIE}</div>
        <div><b>Cislo OP:</b> {trespass.C_OP}</div>
        <div>
          <IconButton
            label="Evidovať"
            style={{ width: 120, height: 120, padding: 30 }}
            onClick={() => this.handleOpenPersonSearch(trespass)}
            toolTip="Evidovať"
            toolTipPlace="bottom"
            disabled={this.state.disableTrespassAdd}
            size="large">
            <AddIcon />
          </IconButton>
        </div>
      </div>
    );

    this.setState({
      detail: detail,
      open: true
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const length = this.props.trespasses.length;

    return (
      <div>
        <AppBar title='Osoba v priestupkoch' closable />
        <LinearProgress loading={this.props.isLoading} />
        <div className='container'>
          <Paper className='content' elevation={1} >
            <React.Fragment>
              <h3>Priestupkov: {length}</h3>
              {(length === 0 && !this.props.isLoading) &&
                <Dialog
                  open={this.state.openDialog}
                  onClose={this.handleCloseAlert}
                  contentText={'Osoba nemá zaevidovaný žiaden priestupok.'}
                  title='Otázka'
                  actions={[
                    {
                      label: 'Zrušiť',
                      color: 'primary',
                      onClick: this.handleCloseAlert,
                      keyboardFocused: false
                    },
                    {
                      disabled: this.state.disableTrespassAdd,
                      label: 'Zaevidovať priestupok',
                      color: 'primary',
                      onClick: this.newTrespass,
                      keyboardFocused: true
                    }
                  ]}
                />
              }
              {(length > 0 && !this.props.isLoading) &&
                <List>
                  {this.props.trespasses.map((trespass) =>
                    <Item key={trespass.I_PR} data={trespass} handleOpen={this.handleOpen} />
                  )}
                </List>
              }
            </React.Fragment>
          </Paper>
        </div>
        {(length > 0 && !this.props.isLoading) &&
          <Detail
            open={this.state.open}
            detail={this.state.detail}
            handleClose={this.handleClose} />
        }
      </div>
    );
  }

  componentDidMount() {
    const form = this.props.location.state;
    if (form) {
      this.props.dispatch(trespassActions.getTrespassesByPerson(form, this.props.meta.checkAppOffline));
    }
    else {
      this.props.navigate(routes.PERSON_SEARCH);
    }
    var disTresAdd = true;
    if (this.props.user.Groups.find(x => (x === 1700 || x === 1706)) ? true : false)
      disTresAdd = false;

    this.setState({ disableTrespassAdd: disTresAdd });
  }
}

class Item extends React.Component {
  render() {
    return (
      <ListItem
        onClick={(event) => this.props.handleOpen(this.props.data.I_PR)}
      >
        <div className="item">
          <div><b>Dňa:</b> {convert.convertDate(this.props.data.D_PR)}</div>
          <div><b>Meno a priezvisko priestupcu:</b> {this.props.data.OSOBA}</div>
          <div><b>Dát. nar.:</b> {convert.convertDate(this.props.data.D_NAR)}</div>
          <div><b>Adresa TP:</b> {this.props.data.ADRESA}</div>
          <div><b>Porušenie predpisu:</b> {this.props.data.N_PRED}</div>
          <div><b>Rola v priestupku:</b> {this.props.data.ROLA}</div>
          <div><b>Ulica priestupku:</b> {this.props.data.N_U}</div>
          <div><b>Motorové vozidlo:</b> {this.props.data.ECV}</div>
          <div><b>Spôsob riešenia:</b> {this.props.data.RIESENIE}</div>
        </div>
      </ListItem>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trespasses: state.trespassesByPerson.data,
    isLoading: state.trespassesByPerson.isLoading,
    user: state.user.data,
  }
}

export default withRouter(withMeta(connect(mapStateToProps)(TrespassPerson)));