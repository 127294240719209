import { useWidthV5 } from "../hooks";
import { isMobileActive } from './viewUtils';

/**
 * withWidth doplna propsu isMobile: true/false
 * @module
 * @param {element} WrappedComponent - Wrapped component
 */
const withWidth = () => (WrappedComponent) => (props) => {
  const isMobile = isMobileActive(useWidthV5());
  return <WrappedComponent {...props} isMobile={isMobile} />;
};

export default withWidth;