const actionTypes = {
  SHOW_REQUEST_FAILURE_DIALOG: 'SHOW_REQUEST_FAILURE_DIALOG',
  HIDE_REQUEST_FAILURE_DIALOG: 'HIDE_REQUEST_FAILURE_DIALOG',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  SHOW_MSG: 'SHOW_MSG',
  HIDE_MSG: 'HIDE_MSG',

  SHOW_ERROR_MSG: 'SHOW_ERROR_MSG',
  HIDE_ERROR_MSG: 'HIDE_ERROR_MSG',

  GET_TRESPASSES_BY_CAR_REQUEST: 'GET_TRESPASSES_BY_CAR_REQUEST',
  GET_TRESPASSES_BY_CAR_SUCCESS: 'GET_TRESPASSES_BY_CAR_SUCCESS',
  GET_TRESPASSES_BY_CAR_FAILURE: 'GET_TRESPASSES_BY_CAR_FAILURE',

  GET_TRESPASS_PHOTOS_REQUEST: 'GET_TRESPASS_PHOTOS_REQUEST',
  GET_TRESPASS_PHOTOS_SUCCESS: 'GET_TRESPASS_PHOTOS_SUCCESS',
  GET_TRESPASS_PHOTOS_FAILURE: 'GET_TRESPASS_PHOTOS_FAILURE',

  GET_TRESPASSES_BY_PERSON_REQUEST: 'GET_TRESPASSES_BY_PERSON_REQUEST',
  GET_TRESPASSES_BY_PERSON_SUCCESS: 'GET_TRESPASSES_BY_PERSON_SUCCESS',
  GET_TRESPASSES_BY_PERSON_FAILURE: 'GET_TRESPASSES_BY_PERSON_FAILURE',

  GET_PEOPLE_REQUEST: 'GET_PEOPLE_REQUEST',
  GET_PEOPLE_SUCCESS: 'GET_PEOPLE_SUCCESS',
  GET_PEOPLE_FAILURE: 'GET_PEOPLE_FAILURE',

  GET_DOGS_REQUEST: 'GET_DOGS_REQUEST',
  GET_DOGS_SUCCESS: 'GET_DOGS_SUCCESS',
  GET_DOGS_FAILURE: 'GET_DOGS_FAILURE',

  GET_EVO_REQUEST: 'GET_EVO_REQUEST',
  GET_EVO_SUCCESS: 'GET_EVO_SUCCESS',
  GET_EVO_FAILURE: 'GET_EVO_FAILURE',

  GET_PARKING_ALPR_REQUEST: 'GET_PARKING_ALPR_REQUEST',
  GET_PARKING_ALPR_SUCCESS: 'GET_PARKING_ALPR_SUCCESS',
  GET_PARKING_ALPR_FAILURE: 'GET_PARKING_ALPR_FAILURE',

  GET_PARKING_EXT_INFO: 'GET_PARKING_EXT_INFO',

  GET_PARKING_CARDS_REQUEST: 'GET_PARKING_CARDS_REQUEST',
  GET_PARKING_CARDS_SUCCESS: 'GET_PARKING_CARDS_SUCCESS',
  GET_PARKING_CARDS_FAILURE: 'GET_PARKING_CARDS_FAILURE',
  CLEAR_PARKING_CARDS: 'CLEAR_PARKING_CARDS',

  GET_PARKING_CARDS_LIST_REQUEST: 'GET_PARKING_CARDS_LIST_REQUEST',
  GET_PARKING_CARDS_LIST_SUCCESS: 'GET_PARKING_CARDS_LIST_SUCCESS',
  GET_PARKING_CARDS_LIST_FAILURE: 'GET_PARKING_CARDS_LIST_FAILURE',
  CLEAR_PARKING_CARDS_LIST: 'CLEAR_PARKING_CARDS_LIST',


  GET_PARKING_TICKETS_REQUEST: 'GET_PARKING_TICKETS_REQUEST',
  GET_PARKING_TICKETS_SUCCESS: 'GET_PARKING_TICKETS_SUCCESS',
  GET_PARKING_TICKETS_FAILURE: 'GET_PARKING_TICKETS_FAILURE',
  GET_PRESCRIPTS_REQUEST: 'GET_PRESCRIPTS_REQUEST',
  GET_PRESCRIPTS_SUCCESS: 'GET_PRESCRIPTS_SUCCESS',
  GET_PRESCRIPTS_FAILURE: 'GET_PRESCRIPTS_FAILURE',
  GET_SOLUTIONS_REQUEST: 'GET_SOLUTIONS_REQUEST',
  GET_SOLUTIONS_SUCCESS: 'GET_SOLUTIONS_SUCCESS',
  GET_SOLUTIONS_FAILURE: 'GET_SOLUTIONS_FAILURE',
  GET_STREETS_REQUEST: 'GET_STREETS_REQUEST',
  GET_STREETS_SUCCESS: 'GET_STREETS_SUCCESS',
  GET_STREETS_FAILURE: 'GET_STREETS_FAILURE',
  GET_PARTS_TOWN_REQUEST: 'GET_PARTS_TOWN_REQUEST',
  GET_PARTS_TOWN_SUCCESS: 'GET_PARTS_TOWN_SUCCESS',
  GET_PARTS_TOWN_FAILURE: 'GET_PARTS_TOWN_FAILURE',
  ADD_TRESPASS_REQUEST: 'ADD_TRESPASS_REQUEST',
  ADD_TRESPASS_SUCCESS: 'ADD_TRESPASS_SUCCESS',
  ADD_TRESPASS_FAILURE: 'ADD_TRESPASS_FAILURE',

  GET_MSG_READ_REQUEST: 'GET_MSG_READ_REQUEST',
  GET_MSG_READ_SUCCESS: 'GET_MSG_READ_SUCCESS',
  GET_MSG_READ_FAILURE: 'GET_MSG_READ_FAILURE',

  GET_MSG_UNREAD_REQUEST: 'GET_MSG_UNREAD_REQUEST',
  GET_MSG_UNREAD_SUCCESS: 'GET_MSG_UNREAD_SUCCESS',
  GET_MSG_UNREAD_FAILURE: 'GET_MSG_UNREAD_FAILURE',

  SET_READED_REQUEST: 'SET_READED_REQUEST',
  SET_READED_SUCCESS: 'SET_READED_SUCCESS',
  SET_READED_FAILURE: 'SET_READED_FAILURE',

  GET_BLOCKS_REQUEST: 'GET_BLOCKS_REQUEST',
  GET_BLOCKS_SUCCESS: 'GET_BLOCKS_SUCCESS',
  GET_BLOCKS_FAILURE: 'GET_BLOCKS_FAILURE',

  GET_ORDER_BLOCKS_REQUEST: 'GET_ORDER_BLOCKS_REQUEST',
  GET_ORDER_BLOCKS_SUCCESS: 'GET_ORDER_BLOCKS_SUCCESS',
  GET_ORDER_BLOCKS_FAILURE: 'GET_ORDER_BLOCKS_FAILURE',

  GET_REQUIRED_BLOCKS_REQUEST: 'GET_REQUIRED_BLOCKS_REQUEST',
  GET_REQUIRED_BLOCKS_SUCCESS: 'GET_REQUIRED_BLOCKS_SUCCESS',
  GET_REQUIRED_BLOCKS_FAILURE: 'GET_REQUIRED_BLOCKS_FAILURE',

  GET_CITY_OF_SETUP_REQUEST: 'GET_CITY_OF_SETUP_REQUEST',
  GET_CITY_OF_SETUP_SUCCESS: 'GET_CITY_OF_SETUP_SUCCESS',
  GET_CITY_OF_SETUP_FAILURE: 'GET_CITY_OF_SETUP_FAILURE',

  GET_ADRESS_OF_SETUP_REQUEST: 'GET_ADRESS_OF_SETUP_REQUEST',
  GET_ADRESS_OF_SETUP_SUCCESS: 'GET_ADRESS_OF_SETUP_SUCCESS',
  GET_ADRESS_OF_SETUP_FAILURE: 'GET_ADRESS_OF_SETUP_FAILURE',

  GET_EVENT_TYPES_REQUEST: 'GET_EVENT_TYPES_REQUEST',
  GET_EVENT_TYPES_SUCCESS: 'GET_EVENT_TYPES_SUCCESS',
  GET_EVENT_TYPES_FAILURE: 'GET_EVENT_TYPES_FAILURE',

  GET_MAP_MARKER_REQUEST: 'GET_MAP_MARKER_REQUEST',
  GET_MAP_MARKER_SUCCESS: 'GET_MAP_MARKER_SUCCESS',
  GET_MAP_MARKER_FAILURE: 'GET_MAP_MARKER_FAILURE',

  SET_MAP_MARKER_REQUEST: 'SET_MAP_MARKER_REQUEST',
  SET_MAP_MARKER_SUCCESS: 'SET_MAP_MARKER_SUCCESS',
  SET_MAP_MARKER_FAILURE: 'SET_MAP_MARKER_FAILURE',

  GET_MAP_FOTO_REQUEST: 'GET_MAP_FOTO_REQUEST',
  GET_MAP_FOTO_SUCCESS: 'GET_MAP_FOTO_SUCCESS',
  GET_MAP_FOTO_FAILURE: 'GET_MAP_FOTO_FAILURE',

  GET_PERSON_LIST_REQUEST: 'GET_PERSON_LIST_REQUEST',
  GET_PERSON_LIST_SUCCESS: 'GET_PERSON_LIST_SUCCESS',
  GET_PERSON_LIST_FAILURE: 'GET_PERSON_LIST_FAILURE',

  GET_PERSON_REQUEST: 'GET_PERSON_REQUEST',
  GET_PERSON_SUCCESS: 'GET_PERSON_SUCCESS',
  GET_PERSON_FAILURE: 'GET_PERSON_FAILURE',

  GET_ACCESS_PERSON_REQUEST: 'GET_ACCESS_PERSON_REQUEST',
  GET_ACCESS_PERSON_SUCCESS: 'GET_ACCESS_PERSON_SUCCESS',
  GET_ACCESS_PERSON_FAILURE: 'GET_ACCESS_PERSON_FAILURE',

  GET_EDITABLE_FIELDS_REQUEST: 'GET_EDITABLE_FIELDS_REQUEST',
  GET_EDITABLE_FIELDS_SUCCESS: 'GET_EDITABLE_FIELDS_SUCCESS',
  GET_EDITABLE_FIELDS_FAILURE: 'GET_EDITABLE_FIELDS_FAILURE',

  GET_DESCRIPTION_FLAG_REQUEST: 'GET_DESCRIPTION_FLAG_REQUEST',
  GET_DESCRIPTION_FLAG_SUCCESS: 'GET_DESCRIPTION_FLAG_SUCCESS',
  GET_DESCRIPTION_FLAG_FAILURE: 'GET_DESCRIPTION_FLAG_FAILURE',
}

export default actionTypes;