import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Button from './Button';

const styles = theme => ({
  button: {
    marginTop: "10px",
    fontWeight: "bold"
  },
});

class FlatButtonComponent extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Button
        className={classes.button}
        label={this.props.label}
        color={this.props.color}
        onClick={this.props.onClick}
        //$ = transient props
        $keyboardFocused={this.props.keyboardFocused}
        //variant " flat " deprecated, text is the equivalent
        variant='text'
        disabled={this.props.disabled}
      >
        {this.props.label}
      </Button >
    )
  }
}

FlatButtonComponent.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  keyboardFocused: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default withStyles(styles)(FlatButtonComponent);
