import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ _theme }) => ({
  textField: {
    // TODO: v MUI5 premysliet ako to obist, lebo label je sivy natvrdo
    // fontStyle: "normal!important",
    // '& .MuiInput-underline:before': {
    //   borderBottomColor: 'gray',
    // },
    // '& .MuiInput-underline:after': {
    //   borderBottomColor: 'gray',
    // },
    // '& .MuiInputLabel-root.Mui-focused': {
    //   color: 'gray!important',
    // },
    // '& .MuiInputLabel-root.Mui-error': {
    //   color: 'rgb(211, 47, 47)!important',
    // },
    // '& .MuiFormHelperText-root.Mui-error': {
    //   color: 'rgb(211, 47, 47)!important',
    // },
    // '& .MuiInputBase-root.Mui-error': { // farba input textu pokial je nevalidny
    //   color: 'rgb(211, 47, 47)!important',
    // },
  },
}));

const TextFieldComponent = ({
  name, label, type, value, onChange, onBlur, margin, style, errorText, error, fullwidth, disabled, multiLine, rows, minRows, maxRows, variant, inputProps, onKeypress, className,
}) => {
  const classes = useStyles();

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(name, event.target.value);
    }
  };

  return (
    <TextField
      id={name}
      label={label}
      type={type}
      value={value}
      onChange={(event) => onChange(name, event.target.value)}
      onBlur={handleBlur}
      margin={margin}
      style={style}
      helperText={errorText}
      error={error}
      fullWidth={fullwidth}
      disabled={disabled}
      multiline={multiLine}
      rows={rows}
      minRows={minRows}
      maxRows={maxRows}
      variant={variant ?? "standard"}
      InputProps={inputProps}
      InputLabelProps={{ root: classes.inputLabel }}
      inputProps={{ ...inputProps, id: name }}
      onKeyPress={onKeypress}
      className={className}
      classes={{ root: classes.textField }}
    />
  );
};

TextFieldComponent.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  margin: PropTypes.string,
  style: PropTypes.object,
  errorText: PropTypes.string,
  error: PropTypes.bool,
  fullwidth: PropTypes.bool,
  disabled: PropTypes.bool,
  multiLine: PropTypes.bool,
  rows: PropTypes.number,
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  inputProps: PropTypes.object,
  inputLabelProps: PropTypes.object,
  onKeypress: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object,
};

export default TextFieldComponent;
