import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';

/**
 * PaperComponent component
 * @module
 * @param {string} [className] - Classnames of element
 * @param {number} [elevation] - Shadow depth
 * @param {bool} [square] - Whether the corners are rounded or not
 * @param {object} [style] - Style object
 * @param {element} [children] - Paper child element
*/
class PaperComponent extends React.Component {
  render() {
    return (
      <Paper
        className={this.props.className}
        elevation={this.props.elevation}
        square={this.props.square}
        style={this.props.style}
      >
        {this.props.children}
      </Paper>
    )
  }
}

PaperComponent.propTypes = {
  className: PropTypes.string,
  elevation: PropTypes.number,
  square: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.element,
}

export default PaperComponent;