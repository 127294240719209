import React from 'react';
import PropTypes from 'prop-types';

import Fab from '@mui/material/Fab';

class FabComponent extends React.Component {
  render() {
    return (
      <Fab
        color={this.props.color}
        className={this.props.className}
        label={this.props.label}
        fullwidth={this.props.fullwidth}
        disabled={this.props.disabled}
        style={this.props.style}
        onClick={this.props.onClick}
        keyboardfocused={this.props.keyboardFocused}
        variant={this.props.variant}
        size={this.props.size}
        mini={this.props.mini}
        type={this.props.type}
        disableRipple={this.props.disableRipple}
      >
        {this.props.children}
      </Fab>
    )
  }
}

FabComponent.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  fullwidth: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  keyboardFocused: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  mini: PropTypes.bool,
  type: PropTypes.string,
  disableRipple: PropTypes.bool
}

export default FabComponent;