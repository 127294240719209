import React from 'react';
import PropTypes from 'prop-types';
import NavigationClose from '../dataDisplay/icons/NavigationClose';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import withRouter from '../utils/withRouter';

/**
 * Appbar component
 * @module
 * @param {string} [title] - Appbar title
 * @param {func} [onClick] - Click handler
 * @param {bool} [closable] - Whether the iconButton is visible
 * @param {element} [userEl] - User element
 * @param {element} [logoEl] - Logo element
 */
class AppBarComponent extends React.Component {
  handleClose = () => {
    this.props.navigate(-1);
  }

  render() {
    return (
      (<AppBar position="static">
        <Toolbar>
          {this.props.logoEl}
          <IconButton
            style={{ visibility: this.props.closable ? 'visible' : 'hidden' }}
            className="menuButton"
            color="inherit"
            aria-label="Menu"
            onClick={this.handleClose}
            size="large">
            <NavigationClose />
          </IconButton>
          <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }}>
            {this.props.title}
          </Typography>
          {this.props.userEl}
        </Toolbar>
      </AppBar>)
    );
  }
}

AppBarComponent.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  closable: PropTypes.bool,
  userEl: PropTypes.element,
  logoEl: PropTypes.element
}

export default withRouter(AppBarComponent);