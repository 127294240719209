import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { blue } from '@mui/material/colors';
import { alpha, styled } from '@mui/material/styles';

// const MySwitch = styled((props) => (
//   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//   '& .MuiSwitch-switchBase.Mui-checked': {
//     color: blue[600],
//     '&:hover': {
//       backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
//     },
//     position: "relative",
//   },
//   '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
//     backgroundColor: blue[600],
//     padding: "9px"  
//   },
// }));

const SwitchComponent = (props) => {

  return (
    <div className='row' style={{ alignItems: 'center' }}>
      <FormControlLabel
        control={
          <Switch

            style={props.styleSwitch}
            color={props.color}
            disabled={props.disabled}
            checked={props.checked}
            onChange={props.onChange}
            value={props.value}

          />
        }
        label={props.label}
      />
    </div>
  );
};

SwitchComponent.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  value: PropTypes.string,
  styleSwitch: PropTypes.object
};

export default SwitchComponent;
