import { combineReducers } from 'redux';
import user from './user/user';
import msg from './user/msg';
import errorMsg from './user/errorMsg';
import trespassesByCar from './trespass/trespassesByCar';
import trespassPhotos from './trespass/trespassPhotos';
import trespassesByPerson from './trespass/trespassesByPerson';
import phRequiredBlocks from './trespass/phRequiredBlocks';
import phOrderBlocks from './trespass/phOrderBlocks';
import people from './trespass/people';
import person from './person/person';
import phAccessPerson from './person/phAccessPerson';
import personList from './person/personList';
import dogs from './dog/dogs';
import evo from './evo/evo';
import cityOfSetup from './evo/cityOfSetup';
import adressOfSetup from './evo/adressOfSetup';
import alpr from './parking/alpr';
import parkingCards from './parking/parkingCards';
import parkingCardsList from './parking/parkingCardsList';
import parkingTickets from './parking/parkingTickets';
import msgRead from './msg/msgRead';
import msgUnRead from './msg/msgUnRead';
import extInfo from './parking/extInfo';
import prescripts from './trespass/prescripts';
import eventTypes from './trespass/eventTypes';
import streets from './street/streets';
import partsTown from './partTown/partsTown';
import mapMarkers from './mapMarker/mapMarkers';
import mapFotos from './mapMarker/mapFotos';
import trespass from './trespass/trespass';
import solutions from './trespass/solutions';
import blocks from './trespass/blocks';
import errorDialog from './errorDialog';
import editableFields from './trespass/editableFields'
import descriptionFlag from './trespass/descriptionFlag';


const rootReducer = combineReducers({
  user,
  msg,
  errorMsg,
  trespassesByCar,
  trespassPhotos,
  trespassesByPerson,
  people,
  person,
  personList,
  dogs,
  evo,
  cityOfSetup,
  adressOfSetup,
  extInfo,
  alpr,
  parkingCards,
  parkingCardsList,
  parkingTickets,
  prescripts,
  eventTypes,
  streets,
  partsTown,
  mapMarkers,
  mapFotos,
  trespass,
  solutions,
  blocks,
  errorDialog,
  msgRead,
  msgUnRead,
  phRequiredBlocks,
  phOrderBlocks,
  phAccessPerson,
  editableFields,
  descriptionFlag,
});

export default rootReducer;