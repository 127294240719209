import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip'

class CheckboxComponent extends React.Component {
  render() {
    return (
      <Tooltip
        title={this.props.toolTip}
        placement={this.props.toolTipPlace}
      >
        <FormControlLabel
          style={this.props.styleLabel}
          control={
            <Checkbox
              checked={this.props.checked}
              onChange={this.props.onChange}
              value={this.props.value}
              color={this.props.color}
              disabled={this.props.disabled}
              style={this.props.style}
            />
          }
          label={this.props.label}
        />
      </Tooltip>
    )
  }
}

CheckboxComponent.propTypes = {
  toolTip: PropTypes.string,
  toolTipPlace: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  style: PropTypes.object,
  styleLabel: PropTypes.object,
}

export default CheckboxComponent;