import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';

import { Link } from 'react-router-dom';
import Divider from '../dataDisplay/Divider';

/**
 * AppBarComponent component
 * @module
 * @param {func} [onClick] - Click handler
 * @param {array} allowedURLS - List of allowed urls
 * @param {func} onLogout - Logout handler
 * @param {array} menu - List of menu items
 */
class AppBarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  handleToggle = () => this.setState({ open: !this.state.open });

  handleClose = () => this.setState({ open: false });

  handleRefresh = () => {
    window.location.reload(true);
  };

  render() {
    return (
      (<div>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              className="menuButton"
              color="inherit"
              aria-label="Menu"
              onClick={this.handleToggle}
              size="large">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              {this.props.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          docked="false"
          width={200}
          open={this.state.open}
          onRequestChange={(open) => this.setState({ open })}
        >
          {this.props.menu.map(x => {
            if (!this.props.allowedURLS || !this.props.allowedURLS.includes(x.Route)) {
              return null;
            }
            else {
              return (
                <Link key={x.MenuID} to={x.Route}>
                  <MenuItem onClick={this.handleClose}> {x.Icon}<dd>{x.Title}</dd></MenuItem>
                </Link>
              );
            }
          })}
          <Divider />
          <MenuItem style={this.props.refreshStyle} onClick={this.handleRefresh}>Aktualizovať</MenuItem>
          <MenuItem style={this.props.logoutStyle} onClick={this.props.onLogout}>Odhlásenie</MenuItem>
        </Drawer>
      </div>)
    );
  }
}

AppBarComponent.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  allowedURLS: PropTypes.array.isRequired,
  onLogout: PropTypes.func.isRequired,
  menu: PropTypes.array.isRequired,
  refreshStyle: PropTypes.object,
  logoutStyle: PropTypes.object,
};

export default AppBarComponent;