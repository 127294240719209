import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FlatButton from '../inputs/FlatButton';
import Slide from '@mui/material/Slide';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ReactHtmlParser from 'react-html-parser';


const styles = theme => ({
  dialogPaper: {
    margin: '1.25rem'
  },
})

function Transition(props) {
  return <Slide direction="down" {...props} />;
}


/**
 * Dialog component
 * @module
 * @param {string} [title] - Dialog title
 * @param {string} [contentText] - Dialog content text
 * @param {element} [content] - Dialog content element
 * @param {array} actions - Dialog actions
 * @param {bool} open - Whether the dialog is open
 * @param {func} [onClose] - Close handler function
 * @param {bool} [modal] - Modal
 */
class DialogComponent extends React.Component {
  render() {
    const { title, contentText, actions, content, classes, closeButton, titleStyle } = this.props;
    return (
      (<Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby='ariaLabelledby'
        aria-describedby='ariaDescribedby'
        fullWidth={true}
        TransitionComponent={Transition}
        classes={{ paper: classes.dialogPaper }}
      >
        {closeButton}
        {title &&
        <DialogTitle id='ariaLabelledby' style={titleStyle}>
          <Typography variant="h6" classes={this.props.titleClasses}>{title}</Typography>
        </DialogTitle>
        }
        <DialogContent classes={this.props.contentClasses}>
          <DialogContentText id='ariaDescribedby' className={this.props.contentTextClasses}>
            {ReactHtmlParser(contentText)}
          </DialogContentText>
          {content}
        </DialogContent>
        <DialogActions>
          {actions.map(x =>
            <dd key={x.label}>
              <FlatButton
                key={x.label}
                label={x.label}
                color={x.color}
                keyboardFocused={x.keyboardFocused}
                onClick={x.onClick}
                disabled={x.disabled}
              >
                {x.label}
              </FlatButton>
            </dd>
          )}
        </DialogActions>
      </Dialog>)
    );
  }
}

DialogComponent.propTypes = {
  title: PropTypes.string,
  contentText: PropTypes.string,
  content: PropTypes.element,
  actions: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  titleClasses: PropTypes.object,
  contentTextClasses: PropTypes.object,
  closeButton: PropTypes.element,
  titleStyle: PropTypes.object,
}

export default withStyles(styles)(DialogComponent);