import actionTypes from '../../actions/ActionTypes';

const _initState = {
  isLoading: false,
  data: [],
}

const descriptionFlag = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_DESCRIPTION_FLAG_REQUEST:
      return { data: [], isLoading: true };

    case actionTypes.GET_DESCRIPTION_FLAG_SUCCESS:
      return { isLoading: false, data: action.res.Data };

    case actionTypes.GET_DESCRIPTION_FLAG_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default descriptionFlag;