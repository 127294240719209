import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';

/**
 * Progress component
 * @module
 * @param {bool} loading - Whether to show progress
 */
class Progress extends React.Component {
  render() {
    if (this.props.loading)
      return <LinearProgress variant="indeterminate" />
    else
      return null;
  }
}

Progress.propTypes = {  
  loading: PropTypes.bool.isRequired,  
}

export default Progress;