import React, { useCallback, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { useWidthV5 } from "../../hooks";
import { getSrvCfg, SRV_CONFIG_KEY } from "../../utils/storage";
import "./EnvBanner.scss";

const INFO_TEXT_SERVER = {
  DEV: "VÝVOJOVÉ",
  TEST: "TESTOVACIE"
};

// vlastna metoda isMobile, pretoze v kniznici je 'xs' + 'sm' povazovane za mobilnu verziu
const isMobileActive = (width) => {
  if (width === "xs") {
    return true;
  }
  return false;
};

/**
* Vykreslenie infornacie ake prostredie je pouzivane pre client aj server
*
* @param {string} [bannerStyle="floatingLayer"] - Styl banneru. Mozne hodnoty:
* - `"floatingLayer"`: vykreslene ako plavajuci prvok (zatial neimplementovane)
* - `"globalLayer"`: baner zobrazeny globalne nad celou strankou
* - `"topBannerLayer"`: baner vykreslovany do svojho rodica
* @param {*} { initPosition, default { right: 40, bottom: 110 } } - pre 'floatingLayer' - uvodna pozicia  a pozicia po renderingu
* @return {*} 
*/
const EnvBanner = ({ bannerStyle = "floatingLayer", initPosition = { right: 40, bottom: 110 } }) => {
  const [envType, setEnvType] = useState(getSrvCfg().envType);
  const isMobile = isMobileActive(useWidthV5());

  useEffect(() => {
    function fromStorage(event) {
      if (event.key === SRV_CONFIG_KEY) {
        setEnvType(JSON.parse(event.newValue)?.envType);
      }
    }

    window.addEventListener("storage", fromStorage);
    return () => {
      window.removeEventListener("storage", fromStorage);
    };
  }, []);

  let textServer = "";
  let EnvInfoBanner = null;
  let topBannerTextClasses;

  switch (envType) {
    case 0:
      textServer = INFO_TEXT_SERVER.DEV;
      break;
    case 1:
      textServer = INFO_TEXT_SERVER.TEST;
      break;
    default:
  }

  switch (isMobile) {
    case true:
      topBannerTextClasses = "env-banner-text-mobile";
      break;
    case false:
      topBannerTextClasses = "env-banner-text-desktop";
      break;
    default:
  }

  // baner zobrazeny v plavajucej vrstve s Drag&Drop moznostou
  const FloatingLayer = () => {
    const [position, setPosition] = useState({ right: initPosition.right, bottom: initPosition.bottom });
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [isResizing, setIsResizing] = useState(false);

    const handleMouseDown = (e) => {
      setIsDragging(true);
      setDragOffset({
        x: window.innerWidth - e.clientX - position.right,
        y: window.innerHeight - e.clientY - position.bottom,
      });
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;
      setPosition({
        right: window.innerWidth - e.clientX - dragOffset.x,
        bottom: window.innerHeight - e.clientY - dragOffset.y,
      });
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    useEffect(() => {
      const handleResize = () => {
        setIsResizing(true);
        // timeout, aby sa animacia spustila az po ukonceni zmeny velkosti okna
        clearTimeout(window.resizeTimeout);
        window.resizeTimeout = setTimeout(() => {
          setIsResizing(false);
        }, 1000); // Po case zresetuj stav isResizing
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        clearTimeout(window.resizeTimeout);
      };
    }, []);

    return (
      <Box
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        className='env-banner-floating'
        sx={{
          right: position.right,
          bottom: position.bottom,
          width: isMobile ? '5.2rem' : '7rem',
          height: isMobile ? '5.2rem' : '7rem',
          outlineWidth: isMobile ? '0.7rem' : '1rem',
          animation: isResizing ? 'none' : 'pulse 1.4s ease-out forwards',
        }}
      >
        <Stack spacing={1} direction="row" >
          {(envType === 0 || envType === 1) && (
            <Box className={topBannerTextClasses}>
              {textServer}<br />prostredie
            </Box>
          )}
        </Stack >
      </Box>
    );
  };

  // baner zobrazeny globalne nad celou strankou
  const GlobalLayer = useCallback(() => {
    return (
      <Stack className='env-banner-global' spacing={1} direction="row">
        {(envType === 0 || envType === 1) && (
          <>
            <Box className={topBannerTextClasses}>
              {textServer}
              <Box className='env-banner-text-inline'>&nbsp;prostredie</Box>
            </Box>
          </>
        )}
      </Stack >
    );
  }, [envType, textServer, topBannerTextClasses]);

  // baner vykreslovany do svojho rodica
  const TopBannerLayer = useCallback(() => {
    return (
      <Stack className='env-banner-top' spacing={1} direction="row" >
        {(envType === 0 || envType === 1) && (
          <>
            <Box className={topBannerTextClasses}>
              {textServer}
              <Box className='env-banner-text-inline'>&nbsp;prostredie</Box>
            </Box>
          </>
        )}
      </Stack >
    );
  }, [envType, textServer, topBannerTextClasses]);

  switch (bannerStyle) {
    case "floatingLayer":
      EnvInfoBanner = FloatingLayer;
      break;
    case "globalLayer":
      EnvInfoBanner = GlobalLayer;
      break;
    case "topBannerLayer":
      EnvInfoBanner = TopBannerLayer;
      break;
    default:
  }

  if (envType === 2) {
    // pre PROD prostredia info nechceme vypisovat
    return null;
  } else {
    return <EnvInfoBanner />;
  }
};

export default EnvBanner;