import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';

/**
 * List component
 * @module
 */
class ListComponent extends React.Component {
  render() {
    return (
      <List
        style={this.props.style}
      >
        {this.props.children}
      </List>
    )
  }
}


ListComponent.propTypes = {
   style: PropTypes.object,
}


export default ListComponent;