/**
 * Vrati true, ak sa ma sirka povazovat za mobilnu verziu
 *
 * @param {*} width ('xs', 'sm', 'md', 'lg', 'xl')
 * @return {*} 
 */
export const isMobileActive = (width) => {
  if (width === "xs" || width === "sm") {
    return true;
  }
  return false;
};
