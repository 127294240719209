import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/sk';
import 'moment/locale/de';
import 'moment/locale/en-gb';

import { InputAdornment } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import { Popper } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { skSK } from '@mui/x-date-pickers/locales';
// import { deDE } from '@mui/x-date-pickers/locales';
// import { enUS } from '@mui/x-date-pickers/locales';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DatePickerToolbar } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EventIcon from '@mui/icons-material/Event';

import { withLocalizationConsumer } from '../../coraWebMComponents/localization/withLocalization';
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import FormModal from '../../coraWebMComponents/inputs/FormModal';
import Button from '../../coraWebMComponents/inputs/Button';
import withWidth from '../views/withWidth';

const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme),
  datePickerBgColor: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  datePickerTitleColor: {
    '& .MuiTypography-root.MuiTypography-overline': {
      color: theme.palette.secondary.contrastText,
    },
  },
  actionButton: {
    padding: '8px',
    '& button': {
      color: theme.palette.secondary.main,
      margin: '4px',
      '&:hover': {
        backgroundColor: '#F8F8F8',
      },
    }
  }
}));
/**
 * DatePicker pre MUI verziu 5
 *
 * @param {*} props 
 *  - keyboards - povoluje/zakazuje pouzitie klavesnice v text. poli
 *  - onlyMobileVersion=true - vynutenie len mobilnej verzie pickera, nezavisle od rezimu obrazovky
 *  - placement - umiestnenie Popper v desktop verzii (https://mui.com/material-ui/api/popper/#popper-prop-children)
 *  - slots - override pre slots
 *  - actions - override pre akcne tlacidla slotu actionBar, default: ['today', 'clear', 'cancel', 'accept']
 *  - actionBar - override pre slotProps actionBar
 *  - layout - override pre slotProps layout
 *  - desktopPaper - override pre slotProps desktopPaper
 *  - dialog - override pre slotProps dialog
 *  - popper - override pre slotProps popper
 *  - toolbar - override pre slotProps toolbar
 *  - field - override pre slotProps field
 *  - textField - override pre slotProps textField
 *  - slotProps - override pre slotProps
 *  - zvysne paramentre su standardne podla dokumentacie
 * @return {*} 
 */
const DatePickerV5 = (props) => {
  const classes = useStyles();
  const { translate, onlyMobileVersion = false } = props;
  const [error, setError] = useState(null);
  const [pickerOpen, setPickerOpen] = useState(false);
  const { isMobile } = props;
  const mobileVersion = onlyMobileVersion || isMobile;
  const PickerComponent = mobileVersion ? MobileDatePicker : DatePicker;

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'maxDate': {
        return translate('datepicker.maxDateMessage');
      }
      case 'minDate': {
        return translate('datepicker.minDateMessage');
      }
      case 'disableFuture': {
        return translate('datepicker.maxDateMessage');
      }
      case 'disablePast': {
        return translate('datepicker.minDateMessage');
      }
      case 'invalidDate': {
        return translate('datepicker.invalidDateMessage');
      }
      default: {
        return '';
      }
    }
  }, [error, translate]);

  const CustomToolbar = (propsCustom) => {
    return (
      <Box
        className={propsCustom.className}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <DatePickerToolbar {...propsCustom}
          className={classes.datePickerBgColor}
          classes={{ root: classes.datePickerTitleColor }}
          sx={{
            display: 'flex', alignItems: 'left', justifyContent: 'space-between', flexGrow: true, flex: 1,
          }}
        />
      </Box>
    );
  };

  const CustomPopper = (props) => {
    if (props.open)
      return (
        <FormModal
          open={props.open}
          // onClose={}
          elements={
            <Popper
              {...props}
              sx={{
                zIndex: 10001,
                overflow: "auto",
                maxHeight: '100vh',
              }}
            />
          }
        >
        </FormModal>
      );
    else {
      return null;
    }
  };

  const onChange = (value, context) => {
    // nastav value len ak je pisane klavesnicou do DateField (nie klikane do kalendara)
    if (!pickerOpen && !context.validationError) {
      value = value !== null ? value.toDate() : null;
      props.onChange(props.name, value);
    }
    // console.log("onChange: ", value);
  };

  const onOpen = () => {
    setPickerOpen(true);
    // console.log("onOpen");
  };

  const onAccept = (value, _context) => {
    value = value !== null ? value.toDate() : null;
    props.onChange(props.name, value);
    // console.log("onAccept: ", value);
  };

  const onClear = (_context) => {
    props.onChange(props.name, null);
    // console.log("onClear: ");
  };

  const onClose = () => {
    setPickerOpen(false);
    // console.log("onClose: ");
  };

  const onError = (error, _value) => {
    setError(error);
  };

  return (
    // TODO: zvazit presun LocalizationProvider do App.js namiesto
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={props.locale} localeText={{
      ...skSK.components.MuiLocalizationProvider.defaultProps.localeText,
      clearButtonLabel: translate('datepicker.clearLabel'),
      cancelButtonLabel: translate('datepicker.cancelLabel'),
      okButtonLabel: translate('datepicker.okLabel'),
      todayButtonLabel: translate('datepicker.todayLabel'),
      datePickerToolbarTitle: translate('datepicker.toolbarTitle'),
    }}>
      <PickerComponent
        name={props.name}
        label={props.label}
        format={props.format} // ak chces nedoplnat uvodnu nulu, nastav shouldRespectLeadingZeros=true
        defaultValue={props.defaultValue && moment(props.defaultValue)}
        value={props.value && moment(props.value)}
        open={props.open || pickerOpen}
        onAccept={props.onAccept ?? onAccept}
        onChange={onChange}
        onClose={props.onClose ?? onClose}
        onError={props.onError ?? onError}
        onMonthChange={props.onMonthChange}
        onOpen={props.onOpen ?? onOpen}
        onSelectedSectionsChange={props.onSelectedSectionsChange}
        onViewChange={props.onViewChange}
        onYearChange={props.onYearChange}
        clearable={props.clearable}
        disableFuture={props.disableFuture}
        disablePast={props.disablePast}
        className={props.className}
        closeOnSelect={props.closeOnSelect}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        disableOpenPicker={props.disableOpenPicker}
        displayWeekNumber={props.displayWeekNumber}
        fixedWeekNumber={props.fixedWeekNumber}
        formatDensity={props.formatDensity}
        inputRef={props.inputRef}
        loading={props.loading}
        localeText={props.localeText}
        maxDate={props.maxDate && moment(props.maxDate)}
        minDate={props.minDate && moment(props.minDate)}
        monthsPerRow={props.monthsPerRow}
        openTo={!props.value ? props.openTo : null} //ak hodnota je nastavena, ukaz ju rovno v kalendari
        orientation={props.orientation}
        reduceAnimations={props.reduceAnimations}
        referenceDate={props.referenceDate && moment(props.referenceDate)}
        renderLoading={props.renderLoading}
        selectedSections={props.selectedSections}
        shouldDisableDate={props.shouldDisableDate}
        shouldDisableMonth={props.shouldDisableMonth}
        shouldDisableYear={props.shouldDisableYear}
        showDaysOutsideCurrentMonth={props.showDaysOutsideCurrentMonth}
        sx={props.sx}
        timezone={props.timezone}
        view={props.view}
        viewRenderers={props.viewRenderers}
        views={props.views ?? ['year', 'month', 'day']}
        yearsPerRow={props.yearsPerRow ?? 3}
        yearsOrder={props.yearsOrder}

        slots={{
          toolbar: (propsCustom) => CustomToolbar(propsCustom),
          popper: !mobileVersion && CustomPopper,
          ...props.slots
        }}

        slotProps={{
          openPickerIcon: {
            color: 'primary',
          },
          textField: {
            variant: 'standard',
            fullWidth: props.fullWidth,

            ...(props.keyboards === true && {
              readOnly: false,
              onKeyDown: (event) => {
                if (event.key === 'Enter') {
                  event.preventDefault(); // Zastavit otvorenie DatePickeru ak je v rezime keyboards=true
                }
              },
              onClick: (event) => {
                event.stopPropagation(); // Zastavit otvorenie DatePickeru pri kliknuti mysou na textove pole
              },
            }),

            ...(props.keyboards === false && {
              readOnly: true,
            }),

            helperText: errorMessage,
            InputProps: mobileVersion && {
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    style={{ width: '14px', height: '14px', minWidth: '22px', minHeight: '22px' }}
                  >
                    <EventIcon onClick={onOpen} />
                  </Button>
                </InputAdornment>
              ),
            },
            ...props.textField
          },
          field: {
            clearable: props.clearable,
            onClear: onClear,
            shouldRespectLeadingZeros: props.shouldRespectLeadingZeros ?? true, // true=NEdoplni uvodnu nulu, nastavit format=D.M.YYYY nestaci; false=formát vždy pridá úvodné nuly, aj ked je format=D.M.YYYY
            ...props.field
          },
          toolbar: {
            toolbarFormat: 'dd, D. MMMM',
            toolbarPlaceholder: "?",
            hidden: false,
            ...props.toolbar
          },
          popper: {
            placement: props.placement ? props.placement : "auto",
            sx: {
            },
            ...props.popper
          },
          dialog: {
            sx: {
            },
            ...props.dialog
          },
          desktopPaper: {
            sx: {
            },
            ...props.desktopPaper
          },
          layout: {
            sx: {
            },
            ...props.layout
          },
          desktopTrapFocus: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          actionBar: {
            actions: props.actions ?? ['today', 'clear', 'cancel', 'accept'],
            className: classes.actionButton,
            sx: {
            },
            ...props.actionBar
          },
          ...props.slotProps,
        }}
      />
    </LocalizationProvider>
  );
};

DatePickerV5.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  disableFuture: PropTypes.bool.isRequired,
  disablePast: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
};

export default withLocalizationConsumer(withWidth()(DatePickerV5));
